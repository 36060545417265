<template>
  <div class="col-span-1 md:col-span-2 ml-4 md:ml-16 lg:ml-32 mr-4">
    <h2 class="font-bold text-4xl text-body-4 max-w-xl leading-12">
      Jak správně inzerovat
    </h2>
    <img src="/images/Rectangle 243 (7).png" class="mt-12 max-w-2xl w-3/4" />
    <p class="text-body-4 text-17 mt-8 max-w-2xl font-semibold pb-2">
      Zveřejněním inzerátu započnete prodej své nemovitosti. Nastává tedy čas
      zúročení veškeré energie či peněz vložených do přípravy fotografií, videa
      a dalších propagačních materiálů.
    </p>
    <p class="text-body-4 text-17 mt-2 max-w-2xl font-semibold pb-2">
      Jediným kliknutím vložíte inzerát na významné české realitní portály. WOW
      efekt můžete dále umocnit publikováním webu a sdílením na sociální sítě.
    </p>
    <router-link :to="'/pruvodce/4'">
      <button
        class="mt-12 text-15 text-white font-bold px-8 py-4 rounded-md bg-body-3 hover:opacity-80 duration-300 focus:outline-none"
      >
        Pokračovat na právní služby
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
