<template>
  <div class="col-span-1 md:col-span-2 ml-4 md:ml-16 lg:ml-32 mr-4">
    <h2 class="font-bold text-4xl text-body-4 max-w-xl leading-12">
      Prodej nemovitosti a související právní služby
    </h2>
    <img src="/images/Rectangle 243 (8).png" class="mt-12 max-w-2xl w-3/4" />
    <p class="text-body-4 text-17 mt-8 max-w-2xl font-semibold pb-2">
      Legislativní povinnosti spojené s typickým prodejem nemovitosti hravě
      zvládnete s využitím vzorové smlouvy, kterou vám zašle aplikace
      MakléřeNechci. U složitějších prodejů, kde je třeba řešit i kroky navíc,
      jako je například financování hypotéky, se však již pravděpodobně
      neobejdete bez advokáta.
    </p>
    <p class="text-body-4 text-17 mt-2 max-w-2xl font-semibold pb-2">
      Vzhledem k hodnotě nemovitostí je důležité situaci nepodcenit a v případě
      potřeby investovat do právních služeb. Ty sice bývají drahé, avšak ve
      výsledku mnohem víc peněz ušetří.
    </p>
    <p class="text-body-4 text-17 mt-2 max-w-2xl font-semibold pb-2">
      S aplikací MakléřeNechci máte k dispozici výčet autorizovaných advokátů s
      mnohaletými zkušenosti s prodejem nemovitostí. Tito advokáti vám pomohou
      eliminovat veškeré potenciální chyby a zaručí bezproblémový průběh prodeje
      nemovitosti.
    </p>
    <router-link :to="'/pruvodce/1'">
      <button
        class="mt-12 text-15 text-white font-bold px-8 py-4 rounded-md bg-body-3 hover:opacity-80 duration-300 focus:outline-none"
      >
        Zpátky na strategii a dokumentaci
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
