<template>
  <div class="col-span-1 md:col-span-2 ml-4 md:ml-16 lg:ml-32 mr-4">
    <h2 class="font-bold text-4xl text-body-4 max-w-xl leading-12">
      Připravená nemovitost znamená až o 10% víc peněz
    </h2>
    <img src="/images/Rectangle 243 (6).png" class="mt-8 max-w-2xl w-3/4" />
    <p class="text-body-4 text-17 mt-8 max-w-2xl font-semibold">
      Základní schopností dobrého prodejce je umět se vžít do role kupujícího.
      Musíte se tedy oprostit od emocí a nemovitost prezentovat tak, aby splnila
      všechna očekávání a představy zájemce. Představy, ve kterých si buduje
      svůj nový domov. Pouze tak bude ochoten za vaši nemovitost zaplatit vyšší
      cenu.
    </p>
    <p class="text-body-4 text-17 mt-4 max-w-2xl font-semibold">
      Kromě znění inzerátu a vašeho komentáře během prohlídky tak má na prodej
      nemovitosti zásadní vliv stylizace a prezentace bytu či domu.
    </p>
    <p class="text-body-4 text-17 mt-4 max-w-2xl font-semibold">
      Obrázky a další multimédia zkrátka prodávají. Pokud nemáte s
      fotografováním zkušenosti, svěřte jej raději do rukou profesionálů. To
      stejné platí také o natáčení a střihu videa. Přidanou hodnotou pak může
      být profesionální virtuální (3D) prohlídka, která zájemcům ještě víc
      přiblíží prostory nemovitosti.
    </p>
    <p class="text-body-4 text-17 mt-4 max-w-2xl font-semibold">
      S 3D prohlídkou si zájemci o nemovitost mnohem lépe představí reálnou
      podobu jednotlivých místností. Díky tomu, se vám budou ozývat pouze vážní
      zájemci a nemovitost prodáte rychleji a omezíte počet prohlídek vaší
      nemovitosti.
    </p>
    <p class="text-body-4 text-17 mt-4 max-w-2xl font-semibold">
      Ověřeného profesionála na pořízení fotografií, videa či tvorbu 3D
      prohlídky si můžete sjednat přímo v aplikaci MakléřeNechci. Aplikace pro
      vás následně připraví webovou stránku s prezentací, kterou můžete dále
      sdílet například na sociální sítě.
    </p>
    <p class="text-body-4 text-17 mt-4 max-w-2xl font-semibold">
      Získáte tak nejen další prostor k propagaci, ale i profesionální vzhled,
      který je při snaze o prodej nemovitosti nejdůležitější.
    </p>
    <router-link :to="'/pruvodce/3'">
      <button
        class="mt-12 text-15 text-white font-bold px-8 py-4 rounded-md bg-body-3 hover:opacity-80 duration-300 focus:outline-none"
      >
        Pokračovat na inzerci
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
