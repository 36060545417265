<template>
  <div class="col-span-1 md:col-span-2 ml-4 md:ml-16 lg:ml-32 mr-4">
    <h2 class="font-bold text-4xl text-body-4 max-w-xl leading-12">
      Proč je důležitá cenová strategie
    </h2>
    <img src="/images/Rectangle 243 (5).png" class="mt-8 max-w-2xl w-3/4" />
    <p class="text-body-4 text-17 mt-8 max-w-2xl font-semibold pb-2">
      Stanovení adekvátní ceny je základem úspěšné strategie prodeje
      nemovitosti. Její podoba se odvíjí od dvou požadavků:
    </p>
    <li class="font-semibold">
      Potřebuji rychle peníze – v takovém případě je třeba cenu srazit.
    </li>
    <li class="font-semibold">
      Chci z prodeje vytěžit co nejvíc – prodej pravděpodobně potrvá, ale
      dočkáte se více peněz.
    </li>
    <p class="text-body-4 text-17 mt-4 max-w-2xl font-semibold pb-2">
      V prvním případě je důležité cenu nesrazit příliš – jinak sami sebe
      okrádáte o peníze. Ani “přestřelení” ceny však není řešení. Typický
      zájemce o nemovitost ji za draho nekoupí. Vyhledávače postupně začnou
      inzerát posouvat ve výpisu nabídek na zadní pozice, a to i v případě, že
      si zaplatíte topování. V tento moment přicházejí na scénu realitní
      makléři. Počkají si, až cena spadne a nemovitost koupí. Po čase ji pak
      prodají s několikanásobným ziskem. Vy tak proděláte v obou liniích –
      dostanete nižší částku, a navíc po delší době. Jak tedy postupovat
      správně? Nechte si vypracovat cenovou mapu a zajistěte si všechny potřebné
      dokumenty (stáhnete si je na pár kliknutí):
    </p>
    <li class="font-semibold">výpis z katastru</li>
    <li class="font-semibold">list vlastnictví</li>
    <li class="font-semibold">nabývací doklad</li>
    <p class="text-body-4 text-17 mt-4 max-w-2xl font-semibold pb-2">
      Všechny dokumenty zpracované aplikací MakléřeNechci se automaticky přidají
      k vaší nemovitosti.
    </p>
    <router-link :to="'/pruvodce/2'">
      <button
        class="mt-4 text-15 text-white font-bold px-8 py-4 rounded-md bg-body-3 hover:opacity-80 duration-300 focus:outline-none"
      >
        Pokračovat na přípravu nemovitosti
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
