<template>
  <div>
    <main>
      <section>
        <div class="max-w-screen-xl mx-auto mt-4">
          <div class="grid grid-cols-1 md:grid-cols-3">
            <div class="col-span-1 grid-rows-5 ml-4">
              <h2 class="text-3xl">Průvodce prodejem</h2>
              <div class="grid grid-rows-4 mt-4">
                <div class="mt-2">
                  <router-link :to="'/pruvodce/1'">
                    <button
                      class="font-bold text-15 px-4 py-3 rounded-md hover:bg-opacity-20 duration-300 focus:outline-none"
                      :class="
                        $router.history.current.fullPath === '/pruvodce/1'
                          ? 'bg-body-1 bg-opacity-5 text-body-1'
                          : ''
                      "
                    >
                      Strategie a dokumentace
                    </button>
                  </router-link>
                </div>
                <div class="mt-2">
                  <router-link :to="'/pruvodce/2'">
                    <button
                      class="font-bold text-15 px-3 py-3 rounded-md bg-white hover:bg-body-1 hover:bg-opacity-5 duration-300 focus:outline-none"
                      :class="
                        $router.history.current.fullPath === '/pruvodce/2'
                          ? 'bg-body-1 bg-opacity-5 text-body-1'
                          : ''
                      "
                    >
                      Příprava nemovitosti
                    </button>
                  </router-link>
                </div>
                <div class="mt-2">
                  <router-link :to="'/pruvodce/3'">
                    <button
                      class="font-bold text-15 px-3 py-3 rounded-md bg-white hover:bg-body-1 hover:bg-opacity-5 duration-300 focus:outline-none"
                      :class="
                        $router.history.current.fullPath === '/pruvodce/3'
                          ? 'bg-body-1 bg-opacity-5 text-body-1'
                          : ''
                      "
                    >
                      Inzerce
                    </button>
                  </router-link>
                </div>
                <div class="mt-2">
                  <router-link :to="'/pruvodce/4'">
                    <button
                      class="font-bold text-15 px-3 py-3 rounded-md bg-white hover:bg-body-1 hover:bg-opacity-5 duration-300 focus:outline-none"
                      :class="
                        $router.history.current.fullPath === '/pruvodce/4'
                          ? 'bg-body-1 bg-opacity-5 text-body-1'
                          : ''
                      "
                    >
                      Právní služby
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <wizard-view-strategy
              v-if="$route.params.page == 1"
            ></wizard-view-strategy>
            <wizard-view-preparation
              v-else-if="$route.params.page == 2"
            ></wizard-view-preparation>
            <wizard-view-advertising
              v-else-if="$route.params.page == 3"
            ></wizard-view-advertising>
            <wizard-view-lawyer
              v-else-if="$route.params.page == 4"
            ></wizard-view-lawyer>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import WizardViewStrategy from "@/views/wizardInfoAddProject/WizardViewStrategy.vue";
import WizardViewPreparation from "@/views/wizardInfoAddProject/WizardViewPreparation.vue";
import WizardViewAdvertising from "@/views/wizardInfoAddProject/WizardViewAdvertising.vue";
import WizardViewLawyer from "@/views/wizardInfoAddProject/WizardViewLawyer.vue";

export default {
  components: {
    WizardViewStrategy,
    WizardViewPreparation,
    WizardViewAdvertising,
    WizardViewLawyer,
  },
  methods: {},
};
</script>
