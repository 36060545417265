var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('main',[_c('section',[_c('div',{staticClass:"max-w-screen-xl mx-auto mt-4"},[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-3"},[_c('div',{staticClass:"col-span-1 grid-rows-5 ml-4"},[_c('h2',{staticClass:"text-3xl"},[_vm._v("Průvodce prodejem")]),_c('div',{staticClass:"grid grid-rows-4 mt-4"},[_c('div',{staticClass:"mt-2"},[_c('router-link',{attrs:{"to":'/pruvodce/1'}},[_c('button',{staticClass:"font-bold text-15 px-4 py-3 rounded-md hover:bg-opacity-20 duration-300 focus:outline-none",class:_vm.$router.history.current.fullPath === '/pruvodce/1'
                        ? 'bg-body-1 bg-opacity-5 text-body-1'
                        : ''},[_vm._v(" Strategie a dokumentace ")])])],1),_c('div',{staticClass:"mt-2"},[_c('router-link',{attrs:{"to":'/pruvodce/2'}},[_c('button',{staticClass:"font-bold text-15 px-3 py-3 rounded-md bg-white hover:bg-body-1 hover:bg-opacity-5 duration-300 focus:outline-none",class:_vm.$router.history.current.fullPath === '/pruvodce/2'
                        ? 'bg-body-1 bg-opacity-5 text-body-1'
                        : ''},[_vm._v(" Příprava nemovitosti ")])])],1),_c('div',{staticClass:"mt-2"},[_c('router-link',{attrs:{"to":'/pruvodce/3'}},[_c('button',{staticClass:"font-bold text-15 px-3 py-3 rounded-md bg-white hover:bg-body-1 hover:bg-opacity-5 duration-300 focus:outline-none",class:_vm.$router.history.current.fullPath === '/pruvodce/3'
                        ? 'bg-body-1 bg-opacity-5 text-body-1'
                        : ''},[_vm._v(" Inzerce ")])])],1),_c('div',{staticClass:"mt-2"},[_c('router-link',{attrs:{"to":'/pruvodce/4'}},[_c('button',{staticClass:"font-bold text-15 px-3 py-3 rounded-md bg-white hover:bg-body-1 hover:bg-opacity-5 duration-300 focus:outline-none",class:_vm.$router.history.current.fullPath === '/pruvodce/4'
                        ? 'bg-body-1 bg-opacity-5 text-body-1'
                        : ''},[_vm._v(" Právní služby ")])])],1)])]),(_vm.$route.params.page == 1)?_c('wizard-view-strategy'):(_vm.$route.params.page == 2)?_c('wizard-view-preparation'):(_vm.$route.params.page == 3)?_c('wizard-view-advertising'):(_vm.$route.params.page == 4)?_c('wizard-view-lawyer'):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }